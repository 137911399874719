import React from "react";
import { useStaticQuery, graphql } from 'gatsby'
import HubspotForm from 'react-hubspot-form'

import MainContainer from "../../../components/containers/MainContainer";
import HeadDataNew from "../../../data/HeadDataNew";

const CustomerConcernFormPage = () => {
  const data = useStaticQuery(graphql`
    query CustomerConcernFormPageQuery {
      prismicFormsPage {
        data {
          first_form_title
          first_form_description
          first_form_hubspot_id
        }
      }
    }
  `)

  const forms = data.prismicFormsPage.data

  return (
    <MainContainer>
      <HeadDataNew
        title={forms.first_form_title}
        description={forms.first_form_description}
      />

      <div>
        <div className='bg-white w-full pt-16 px-4'>
          <h1 className='font-display font-extrabold text-4xl md:text-5xl text-red-900 text-center tracking-wider leading-tight'>
            {forms.first_form_title}
          </h1>

          <p className='text-gray-900 text-center font-semibold mt-6'>{forms.first_form_description}</p>
        </div>

        <div className='bg-white w-11/12 xl:w-7/12 py-16 mx-auto'>
          <div className='w-full'>
            <HubspotForm
              region="na1"
              portalId='9298297'
              formId={forms.first_form_hubspot_id}
              loading={<div className='text-2xl italic font-bold text-center text-gray-500'>Loading Report Form...</div>}
            />
          </div>
        </div>
      </div>
    </MainContainer>
  )
}

export default CustomerConcernFormPage;